import { Github, Twitter, Codepen, Instagram, Moon, Linkedin, Sun } from "styled-icons/feather";

const Icons = {
    Github,
    Twitter,
    Codepen,
    Instagram,
    Moon,
    Linkedin,
    Sun
}

export default Icons