export const linksPt = [
    {
        label: 'home',
        url: '/'
    },
    {
        label: 'blog',
        url: '/blog'
    },
    // {
    //     label: 'projects',
    //     url: '/projects'
    // }
];

export const linksEn = [
    {
        label: 'home',
        url: '/en'
    },
    {
        label: 'blog',
        url: '/en/blog'
    },
];